let langs = document.querySelector(".langs"),
link = document.querySelectorAll(".lang-opt"),
intro1 = document.querySelector(".lang__intro1"),
intro2 = document.querySelector(".lang__intro2"),
intro3 = document.querySelector(".lang__intro3"),
intro4 = document.querySelector(".lang__intro4"),
intro5 = document.querySelector(".lang__intro5");
presentation = document.querySelector(".lang__intro-presentation");

aboutMeLink = document.querySelector(".lang__aboutme--link");
aboutMeSpan = document.querySelector(".lang__aboutme--span");
ProjectsLink = document.querySelector(".lang__project--link");
ProjectsSpan = document.querySelector(".lang__project--span");
ContactBtnSpan = document.querySelector(".lang__btn-contact--span");

SkillsSpan = document.querySelector(".lang__skills-title");

AboutMeSectionTitle = document.querySelector(".lang__aboutme-section--title");
AboutMeHeaderTitle = document.querySelector(".lang__aboutme-header--title");

ProjectsSectionTitle = document.querySelector(".lang__projects-section--title");
ProjectsHeaderTitle = document.querySelector(".lang__projects-header--title");
ProjectLevelBuddyDescription = document.querySelector(".lang__project-level-buddy--description");
ProjectPongDescription = document.querySelector(".lang__project-pong--description");
ProjectPongSpan = document.querySelector(".lang__project-pong--span");

FaqQ1 = document.querySelector('.lang__faq--q1');
FaqQ2 = document.querySelector('.lang__faq--q2');
FaqQ3 = document.querySelector('.lang__faq--q3');
FaqQ4 = document.querySelector('.lang__faq--q4');
FaqQ5 = document.querySelector('.lang__faq--q5');

FaqA1 = document.querySelector('.lang__faq--a1');
FaqA2 = document.querySelector('.lang__faq--a2');
FaqA3 = document.querySelector('.lang__faq--a3');
FaqA4 = document.querySelector('.lang__faq--a4');
FaqA5 = document.querySelector('.lang__faq--a5');

ContactSectionTitle = document.querySelector(".lang__contact-section--title");
ContactHeaderTitle = document.querySelector(".lang__contact-header--title");
ContactLabel1 = document.querySelector(".lang__contact-label1");
ContactLabel2 = document.querySelector(".lang__contact-label2");
ContactLabel3 = document.querySelector(".lang__contact-label3");
ContactLabel4 = document.querySelector(".lang__contact-label4");
ContactInput1 = document.querySelector(".lang__contact-input1");
ContactInput2 = document.querySelector(".lang__contact-input2");
ContactInput3 = document.querySelector(".lang__contact-input3");
ContactTextArea = document.querySelector(".lang__contact-textarea");
ContactBtnSendSpan = document.querySelector(".lang__contact-send--span");

link.forEach(el=>{
     el.addEventListener("click", ()=>{
          let currentlyActive = langs.querySelector(".active");
          currentlyActive.style.color ="#ffffff";
          currentlyActive.classList.remove("active");

          el.classList.add("active");
          el.style.color = "#f34040";
          
          let attr = el.getAttribute("language")

          // language texts replace

          // Landing section
          intro1.textContent = data[attr].intro1;
          intro2.textContent = data[attr].intro2;
          intro3.textContent = data[attr].intro3;
          intro4.textContent = data[attr].intro4;
          intro5.textContent = data[attr].intro5;
          presentation.textContent = data[attr].presentation;

          aboutMeSpan.textContent = data[attr].aboutMeLink;
          aboutMeLink.setAttribute("data-text", data[attr].aboutMeLink);

          ProjectsSpan.textContent = data[attr].projectsLink;
          ProjectsLink.setAttribute("data-text", data[attr].projectsLink);

          ContactBtnSpan.textContent = data[attr].btnContact;

          // Skills section
          SkillsSpan.textContent = data[attr].skillsTitle;

          // About section
          AboutMeSectionTitle.textContent = data[attr].aboutMeSectionTitle;
          AboutMeHeaderTitle.textContent = data[attr].aboutMeHeaderTitle;

          // Projects section
          ProjectsSectionTitle.textContent = data[attr].projectsSectionTitle;
          ProjectsHeaderTitle.textContent = data[attr].projectsHeaderTitle;
          ProjectLevelBuddyDescription.textContent = data[attr].levelbuddyDescription;
          ProjectPongDescription.textContent = data[attr].pongDescription;
          ProjectPongSpan.textContent = data[attr].pongSpan;

          // FAQ section
          FaqA1.textContent = data[attr].FaqA1;
          FaqA2.textContent = data[attr].FaqA2;
          FaqA3.textContent = data[attr].FaqA3;
          FaqA4.textContent = data[attr].FaqA4;
          FaqA5.textContent = data[attr].FaqA5;

          FaqQ1.textContent = data[attr].FaqQ1;
          FaqQ2.textContent = data[attr].FaqQ2;
          FaqQ3.textContent = data[attr].FaqQ3;
          FaqQ4.textContent = data[attr].FaqQ4;
          FaqQ5.textContent = data[attr].FaqQ5;

          // Contact section
          ContactSectionTitle.textContent = data[attr].contactSectionTitle;
          ContactHeaderTitle.textContent = data[attr].contactHeaderTitle;
          ContactLabel1.textContent = data[attr].contactLabel1;
          ContactLabel2.textContent = data[attr].contactLabel2;
          ContactLabel3.textContent = data[attr].contactLabel3;
          ContactLabel4.textContent = data[attr].contactLabel4;
          ContactInput1.setAttribute("placeholder",data[attr].contactInputPlaceholder1);
          ContactInput2.setAttribute("placeholder",data[attr].contactInputPlaceholder2);
          ContactInput3.setAttribute("placeholder",data[attr].contactInputPlaceholder3);
          ContactTextArea.setAttribute("placeholder",data[attr].contactTextAreaPlaceholder);
          ContactBtnSendSpan.textContent = data[attr].contactBtnSendSpan;
     })
})

let data = {
     english: {
          intro1: "Hi 👋, my name is",
          intro2: "Alessandro Bonomo.",
          intro3: "Software Engineer,",
          intro4: "experienced in Cloud Web Services,",
          intro5: "IoT & Embedded systems.",
          presentation: `
          Hello, I'm a Software Engineer with extensive experience in web and mobile application development, as well as embedded systems such as Espressif devices, Raspberry Pi and Nucleo STM32. I specialize as a full stack developer, and embedded systems development Internet of Things.
          My approach is to understand the client's needs and develop customized solutions to meet them. I am capable of developing high-quality web and mobile applications, integrating cutting-edge technologies such as Next.js, Node, Docker. I also have extensive experience using SQL and NON-SQL databases such as MySQL, MySqlite, MongoDB, Weaviate, Firebase e PostgreSQL.
          Regarding embedded systems development, I have a broad knowledge of Linux and its components, as well as programming low-level devices such as Espressif devices, Raspberry Pi and Nucleo STM32. Additionally, I have experience using communication protocols TCP/UDP, Sockets and RESTful API to create complex IoT systems.
          Furthermore, in my professional activity, I pay particular attention to the security and privacy of the software I develop. I am an expert in implementing security measures such as user authentication, data encryption, and vulnerability management, to ensure the protection of clients' sensitive information.
          Additionally, I am always up-to-date on privacy regulations and laws regarding personal data protection, in order to ensure the applications I develop are compliant with current regulations.
          I am always looking for new challenges and enjoy tackling projects of various sizes, from the simplest to the most complex. If you are looking for a software development expert who is capable of developing tailored solutions for your needs, do not hesitate to contact me.
          `,
          aboutMeLink: "About me",
          projectsLink: "Projects",
          btnContact: "Contact",
          skillsTitle: "My Skills",
          aboutMeSectionTitle: "/About me",
          aboutMeHeaderTitle: "Something about me",
          projectsSectionTitle: "/Projects",
          projectsHeaderTitle: "Some of my works",
          levelbuddyDescription: `
          Level-Buddy is a patent system for monitoring wine levels in wine tanks. Click on the link for more informations about the project.
          `,
          pongDescription: `
          Ratatouille23 is a dockerized full-stack application built with React, NodeJS, and Postgres, deployed on a cloud environment. The project was developed as part of a software engineering exam at the University of Federico II in Naples.
This information system is designed to optimize the management and operations of catering businesses. The development team aimed to create a high-performance and reliable application with user-friendly features for administrators. Key functionalities include user account creation, menu customization, order registration, real-time order tracking for kitchen staff, table-specific billing, and detailed productivity statistics.
It's worth noting that the project has been deployed on a cloud environment, providing users with a smooth and intuitive experience to streamline restaurant operations.
          `,
          pongSpan: `
          Federico II INGSW Project
          `,
          FaqA1:`
          I am available to work all over the world and have experience working with international clients. Thanks to modern technology, I can work remotely and maintain constant communication with my customers, wherever they are.
          `,
          FaqA2:`
          Yes, I have worked with several small businesses to help them develop custom software and improve their work processes.
          `,
          FaqA3:`
          Development time depends on the specific project requirements. However, once I have received the project specifications, I will be able to provide you with an estimate of the time required to complete the work.
          `,
          FaqA4:`
          I implement security measures such as user authentication, data encryption, and vulnerability management, to ensure the protection of clients' sensitive information. Additionally, I am always up-to-date on privacy regulations and laws regarding personal data protection, in order to ensure the applications I develop are compliant with current regulations.
          `,
          FaqA5:`
          During software development, I ensure to create an intuitive and user-friendly user interface. Additionally, I will provide you with adequate training on work processes and software usage.
          `,
          FaqQ1:`
          Do you work internationally?
          `,
          FaqQ2:`
          Do you have experience helping small businesses?
          `,
          FaqQ3:`
          How long does it take to develop custom software?
          `,
          FaqQ4:`
          What are your security standards for the projects developed?
          `,
          FaqQ5:`
          How can I know that the software you develop is easy for me or my employees to use?
          `,
          contactSectionTitle: "/Contact",
          contactHeaderTitle: "Get in touch",
          contactLabel1: "Hey, my name is",
          contactLabel2: "I'm looking for",
          contactLabel3: "Get in touch with me",
          contactLabel4: "I agree with the storage and handling of my data by this website. -",
          contactInputPlaceholder1: "Type Here",
          contactInputPlaceholder2: "Type of Service",
          contactInputPlaceholder3: "Your email here",
          contactTextAreaPlaceholder: "Your message",
          contactBtnSendSpan: "Send",
     },
     italian: {
          intro1: "Ciao 👋, mi chiamo",
          intro2: "Alessandro Bonomo.",
          intro3: "Software Engineer,",
          intro4: "esperto in Cloud Web Services,",
          intro5: "IoT & Embedded systems.",
          presentation:`
          Ciao, sono un Ingegnere del Software con un'ampia esperienza nello sviluppo di applicazioni web e mobile, nonché nei sistemi embedded come i dispositivi Espressif, Raspberry Pi e Nucleo STM32. Mi specializzo come sviluppatore full stack, e nello sviluppo di sistemi embedded Internet of Things (IoT).
Il mio approccio è comprendere le esigenze del cliente e sviluppare soluzioni personalizzate per soddisfarle. Sono in grado di sviluppare applicazioni web e mobile di alta qualità, integrando tecnologie all'avanguardia come Next.js, Node, e Docker. Ho anche un'ampia esperienza nell'uso di database SQL e NoSQL come MySQL, MySqlite, MongoDB, Weaviate, Firebase e PostgreSQL.
Per quanto riguarda lo sviluppo di sistemi embedded, possiedo una conoscenza approfondita di Linux e dei suoi componenti, oltre a programmare dispositivi a basso livello come i dispositivi Espressif, Raspberry Pi e Nucleo STM32. Inoltre, ho esperienza nell'uso di protocolli di comunicazione come TCP/UDP, Sockets e RESTful API per creare sistemi IoT complessi.
Nella mia attività professionale, pongo particolare attenzione alla sicurezza e alla privacy del software che sviluppo. Sono un esperto nell'implementazione di misure di sicurezza come l'autenticazione degli utenti, la crittografia dei dati e la gestione delle vulnerabilità, al fine di garantire la protezione delle informazioni sensibili dei clienti.
Inoltre, sono sempre aggiornato sulle normative sulla privacy e sulle leggi riguardanti la protezione dei dati personali, al fine di assicurare che le applicazioni che sviluppo siano conformi alle normative attuali.
Sono sempre alla ricerca di nuove sfide e mi diverto ad affrontare progetti di varie dimensioni, dai più semplici ai più complessi. Se stai cercando un esperto nello sviluppo software capace di creare soluzioni su misura per le tue esigenze, non esitare a contattarmi.
          `,
          aboutMeLink: "Chi sono",
          projectsLink: "Progetti",
          btnContact: "Contattami",
          skillsTitle: "Le mie Skills",
          aboutMeSectionTitle: "/Chi sono",
          aboutMeHeaderTitle: "Qualcosa su di me",
          projectsSectionTitle: "/Progetti",
          projectsHeaderTitle: "Alcuni dei miei lavori",
          levelbuddyDescription: `
          Level-Buddy è un sistema brevettato per il monitoraggio dei livelli dei vini nei vasi vinari. Clicca sul link per maggiori informazioni.
          `,
          pongDescription: `
Ratatouille23 è un'applicazione full-stack dockerizzata, costruita con React, NodeJS e Postgres, implementata su un ambiente cloud. Il progetto è stato sviluppato come parte di un esame di ingegneria del software presso l'Università Federico II a Napoli.
Questo sistema informativo è stato progettato per ottimizzare la gestione e le operazioni delle attività di ristorazione. Il team di sviluppo ha puntato a realizzare un'applicazione performante e affidabile, con funzionalità user-friendly rivolte agli amministratori. Le principali caratteristiche includono la creazione di account utente, la personalizzazione del menu, la registrazione degli ordini, il tracciamento in tempo reale degli ordini per il personale di cucina, la fatturazione specifica per ogni tavolo e statistiche dettagliate sulla produttività.
          `,
          pongSpan: `
          Ingegneria del software Federico II
          `,
          FaqA1:`
          Sono disponibile per lavorare in tutto il mondo e ho esperienza nel lavorare con clienti internazionali. Grazie alla tecnologia moderna, posso lavorare a distanza e mantenere una comunicazione costante con i miei clienti, ovunque essi si trovino.
          `,
          FaqA2:`
          Sì, ho lavorato con diverse piccole imprese per aiutarle a sviluppare software personalizzato e migliorare i loro processi di lavoro.
          `,
          FaqA3:`
          Il tempo di sviluppo dipende dalle specifiche esigenze del progetto. Tuttavia, una volta che avrò ricevuto le specifiche del progetto, sarò in grado di fornirti una stima del tempo necessario per completare il lavoro.
          `,
          FaqA4:`
          Implemento misure di sicurezza, come l'autenticazione degli utenti, la crittografia dei dati e la gestione delle vulnerabilità, al fine di garantire la protezione delle informazioni sensibili dei clienti. Inoltre, sono sempre aggiornato sulle normative in materia di privacy e sulla regolamentazione delle leggi sulla protezione dei dati personali, al fine di assicurare la conformità delle applicazioni sviluppate alle normative in vigore.
          `,
          FaqA5:`
          Durante lo sviluppo del software, mi assicuro di creare un'interfaccia utente intuitiva e user-friendly. Inoltre, ti fornirò un'adeguata formazione sui processi di lavoro e l'uso del software.
          `,
          FaqQ1:`
          Lavori solo in Italia?
          `,
          FaqQ2:`
          Hai esperienza nell'aiutare piccole imprese?
          `,
          FaqQ3:`
          Quanto tempo ci vuole per sviluppare un software personalizzato?
          `,
          FaqQ4:`
          Quali sono i tuoi standard di sicurezza per i progetti sviluppati?
          `,
          FaqQ5:`
          Come posso sapere che il software che sviluppi è facile da usare per me o per i miei dipendenti?
          `,
          contactSectionTitle: "/Contattami",
          contactHeaderTitle: "Entra in contatto con me",
          contactLabel1: "Ciao, mi chiamo",
          contactLabel2: "Sto cercando",
          contactLabel3: "Ecco la mia email",
          contactLabel4: "Acconsento al trattamento dei dati immessi nel modulo di contatto. -",
          contactInputPlaceholder1: "Il tuo nome",
          contactInputPlaceholder2: "Tipo di servizio",
          contactInputPlaceholder3: "La tua email",
          contactTextAreaPlaceholder: "Il tuo messaggio",
          contactBtnSendSpan: "Invia",
     }
}
